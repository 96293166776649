<template>
    <AModal
        :visible="visible"
        title="Details"
        width="90%"
        @cancel="handleModalCancel"
        :footer="null">
        <MdTable
            :columns="state.columns"
            :data-source="state.data"
            size="small"
            :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
            :pagination="{
                showSizeChanger: true,
                pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                total: state.meta.total,
                pageSize: state.meta.per_page,
                current: state.meta.page,
            }"
            @change="handleTableChange"
            :loading="state.loading">
        </MdTable>
    </AModal>
</template>

<script>
import { defineComponent, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'

export default defineComponent({
    props: {
        visible: [Boolean],
        item: {
            type: [Object],
            default: () => {},
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const state = reactive({
            loading: false,
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            params: {
                rule: props.item.rule,
                page: 1,
                "per-page": 10,
            },
            columns: [
                {
                    title: 'Nama Rule',
                    dataIndex: 'rule',
                },
                {
                    title: 'Nama Produk',
                    dataIndex: 'product_name',
                },
                {
                    title: 'UOM Produk',
                    dataIndex: 'product_uom',
                },
                {
                    title: 'QTY',
                    dataIndex: 'qty',
                },
                {
                    title: 'Poin',
                    dataIndex: 'point',
                },
                {
                    title: 'UOM Program',
                    dataIndex: 'point_uom_name',
                },
                {
                    title: 'Tanggal Mulai',
                    dataIndex: 'start_date',
                },
                {
                    title: 'Tanggal Akhir',
                    dataIndex: 'end_date',
                },
                {
                    title: 'Status',
                    dataIndex: 'status_active',
                },
            ],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const fetchDataList = () => {
            state.loading = true

            apiClient.get('/api/loyalty-point-conversion-by-rule', {
                params: state.params,
            }).then(({ data }) => {
                const { items, _meta } = data
                state.data = items
                state.meta.page = _meta.currentPage
                state.meta.per_page = _meta.perPage
                state.meta.total = _meta.totalCount
            }).finally(() => {
                state.loading = false
            })
        };

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            state,
            handleModalCancel,
            handleTableChange,
        }
    },
})
</script>